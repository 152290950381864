














































import Vue from 'vue';
import SystemParameterService from '@/services/SystemParameterService';
import { AuthenticationType, AuthenticationTypeDropdown } from '@/models/AuthenticationType';
import ChangePasswordDialog from '@/components/ChangePasswordDialog.vue';
import axios from 'axios';
import AuthenticationService from '@/services/AuthenticationService';

export default Vue.extend({
  name: 'Authentications',
  components: { ChangePasswordDialog },
  data() {
    return {
      passwordsEnabled: false,
      AuthenticationType: AuthenticationType,
      AuthenticationTypes: AuthenticationTypeDropdown(),
      selectedAuthenticationType: undefined as
        | { id: AuthenticationType; title: string }
        | undefined,
      showError: false,
      showSuccess: false,
      AuthenticationService: AuthenticationService
    };
  },
  methods: {
    changePassword(password: string, authenticationType: AuthenticationType) {
      this.selectedAuthenticationType = undefined;
      axios
        .post('/api/authentications', {
          authenticationType: authenticationType,
          password: password
        })
        .then((_) => {
          this.showSuccess = true;
          setTimeout(() => (this.showSuccess = false), 1500);
        })
        .catch((error) => {
          this.showError = true;
          setTimeout(() => (this.showError = false), 3000);
        });
    }
  },
  async created() {
    this.passwordsEnabled = await SystemParameterService.getSystemParameterBool('UsePasswords');
  }
});
