


























































































import Vue, { PropType } from 'vue';
import AuthenticationType from '@/models/AuthenticationType';

interface FullAuthenticationType {
  id: AuthenticationType;
  title: string;
}

export default Vue.extend({
  name: 'ChangePasswordDialog',
  props: {
    authenticationType: {
      type: Object as PropType<FullAuthenticationType | undefined>,
      required: false
    }
  },
  data: () => {
    return {
      showModal: false,
      password: '',
      passwordConfirmation: ''
    };
  },
  created() {
    document.addEventListener('keyup', (evt) => {
      if (evt.key === 'Escape') {
        this.cancel();
      }
    });
  },
  methods: {
    reset() {
      this.password = '';
      this.passwordConfirmation = '';
    },
    cancel() {
      this.reset();
      this.showModal = false;
      this.$emit('didCancel');
    },
    save() {
      this.showModal = false;
      this.$emit('didSave', this.password, this.authenticationType?.id);
      this.reset();
    }
  },
  watch: {
    authenticationType(newValue) {
      this.showModal = newValue != undefined;
    },
    showModal(newValue) {
      if (newValue) {
        setTimeout(() => {
          //@ts-ignore
          this.$refs.password.focus();
        }, 10);
      }
    }
  }
});
